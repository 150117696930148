import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core'
import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Login from './login'
import NewPassword from './new_pwd'
import ResetPassword from './reset_pwd'
import { getPlatformClient, theme } from '@NKE/utils'

import { ApolloProvider } from '@apollo/client'

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'themeLoginComponent',
})

const LoginPage = () => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={getPlatformClient()}>
          <BrowserRouter basename='/login'>
            <Route exact path='/' component={Login} />
            <Route path='/newpassword' component={NewPassword} />
            <Route path='/resetpassword' component={ResetPassword} />
          </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default LoginPage

import React, { useState } from 'react'
import {
  Container,
  CssBaseline,
  makeStyles,
  Avatar,
  Typography,
  TextField,
  Button,
  Snackbar,
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { useHistory, useLocation } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import Axios from 'axios'
import { CUSTOMERS_URL } from '@NKE/utils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const NewPassword = () => {
  const [InputData, setInputData] = useState({ newPwd: '', confirmPwd: '' })
  const [Submitting, setSubmitting] = useState(false)
  const [Error, setError] = useState(false)
  const [Open, setOpen] = useState(false)
  const [ErrorText, setErrorText] = useState('')

  const history = useHistory()
  const { search } = useLocation()
  const _classes = useStyles()
  const { t } = useTranslation()

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setInputData(prevInputData => ({ ...prevInputData, [name]: value }))
  }

  const submitNewPwd = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setSubmitting(true)
    setError(false)

    if (!InputData.newPwd || InputData.newPwd !== InputData.confirmPwd) {
      setError(true)
      setSubmitting(false)
      setErrorText(t<string>('emptyPwd'))
      return
    }

    if (search) {
      const _email = search.split('=')[1]

      Axios.post(`${CUSTOMERS_URL}/auth/changepassword`, {
        email: _email,
        password: InputData.newPwd,
      })
        .then(() => {
          setOpen(true)

          setTimeout(() => history.push('/'), 2000)
        })
        .catch(() => {
          setSubmitting(false)
          setError(true)
          setErrorText(t<string>('errorUpdate'))
        })
    }
  }

  const handleClose = () => setOpen(false)

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={_classes.paper}>
        <Avatar className={_classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {t<string>('resetPassword')}
        </Typography>
        <form className={_classes.form} noValidate onSubmit={submitNewPwd}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='newPwd'
            label={t<string>('newPassword')}
            name='newPwd'
            type='password'
            autoFocus
            value={InputData.newPwd}
            onChange={handleChange}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='confirmPwd'
            label={t<string>('confirmPassword')}
            type='password'
            id='confirmPwd'
            value={InputData.confirmPwd}
            onChange={handleChange}
          />
          {Error && <Alert severity='error'>{ErrorText}</Alert>}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={_classes.submit}
            disabled={Submitting}
          >
            {t<string>('save')}
          </Button>
        </form>
      </div>
      <Snackbar open={Open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success'>
          {t<string>('successUpdate')}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default NewPassword

import React, { useState } from 'react'
import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  Button,
  Snackbar,
  makeStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { gql, StoreObject, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

interface IResetPwd {
  customerMutation: {
    resetPwd: StoreObject
  }
}

const RESET_PWD = gql`
  mutation resetPwd($email: String!) {
    customerMutation {
      resetPwd(email: $email) {
        __typename
      }
    }
  }
`

const ResetPassword = () => {
  const [Email, setEmail] = useState('')
  const [Open, setOpen] = useState(false)
  const _classes = useStyles()

  const { t } = useTranslation()

  const [resetPwd, { loading }] = useMutation<IResetPwd, { email: string }>(
    RESET_PWD,
    {
      onCompleted: () => setOpen(true),
    }
  )

  const submitResetPwd = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (Email !== '') {
      resetPwd({ variables: { email: Email } })
    }
  }

  const handleClose = () => setOpen(false)

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={_classes.paper}>
        <Avatar className={_classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {t<string>('forgotPasswordTitle')}
        </Typography>
        <form className={_classes.form} noValidate onSubmit={submitResetPwd}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label={t<string>('emailAddress')}
            name='email'
            autoFocus
            value={Email}
            onChange={event => setEmail(event.target.value)}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={_classes.submit}
            disabled={loading}
          >
            {t<string>('sendEmail')}
          </Button>
        </form>
      </div>
      <Snackbar open={Open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success'>
          {t<string>('successSendEmail')}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default ResetPassword

export default {
  en: {
    translation: {
      invalidCredentials: 'Invalid Credentials',
      connectionError: 'Connection error',
      emailAddress: 'Email Address',
      login: 'Login',
      forgotPassword: 'Forgot your password?',
      forgotPasswordTitle: 'Forgot password',
      sendEmail: 'Send email',
      successSendEmail: 'Email sent for reset password',
      resetPassword: 'Reset password',
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      emptyPwd: 'The Passwords must be equal and not empty',
      errorUpdate: 'Error on updating password',
      save: 'Save',
      successUpdate: 'Password Updated! Redirecting to the login page',
    },
  },
  it: {
    translation: {
      invalidCredentials: 'Credeziali non valide',
      connectionError: 'Errore di connessione',
      emailAddress: 'Indirizzo Email',
      login: 'Login',
      forgotPassword: 'Password dimenticata?',
      forgotPasswordTitle: 'Password dimenticata',
      sendEmail: 'Invia email',
      successSendEmail: 'Email per reimpostare la password inviata',
      resetPassword: 'Reimposta password',
      newPassword: 'Nuova password',
      confirmPassword: 'Conferma password',
      emptyPwd: 'Le password devono essere uguali e non vuote',
      errorUpdate: "Errore durante l'aggiornamento della password",
      save: 'Salva',
      successUpdate:
        'Password aggiornata! Reindirizzamento alla pagina di login',
    },
  },
  es: {
    translation: {
      invalidCredentials: 'Credenciales no válidas',
      connectionError: 'Error de conexión',
      emailAddress: 'emailAddress',
      login: 'Iniciar sesión',
      forgotPassword: '¿Olvidaste tu contraseña?',
      forgotPasswordTitle: 'Olvidaste tu contraseña',
    },
  },
}

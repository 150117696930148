import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { CUSTOMERS_URL, axiosClient, IUserInfo } from '@NKE/utils'
import { Alert } from '@material-ui/lab'
import JwtDecode from 'jwt-decode'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const [inputData, setInputData] = useState({ email: '', password: '' })
  const [submitting, setSubmitting] = useState(false)
  const [ErrorText, setErrorText] = useState('')

  // const { setUserInfo } = useContext(UserInfoContext)
  // const { refreshImg } = useLogo()

  const { t } = useTranslation()

  // const client = useApolloClient()

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setInputData(prevInputData => ({ ...prevInputData, [name]: value }))
  }

  const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitting(true)

    return axiosClient
      .post(CUSTOMERS_URL + '/auth/loginuser', inputData)
      .then(({ data }) => {
        if (data) {
          localStorage.setItem('token', data.Token)
          axiosClient.defaults.headers.common['Authorization'] =
            'JWT ' + data.Token

          const _userInfo = JwtDecode<IUserInfo>(data.Token)
          localStorage.setItem('i18nextLng', _userInfo.region ?? 'en')
          // setUserInfo(_userInfo)
          // refreshImg()

          // client.clearStore()
          window.history.pushState({ from: 'login' }, null, '/')
        }
      })
      .catch(({ response }) => {
        let error
        if (response) {
          error = t('invalidCredentials')
        } else {
          error = t('connectionError')
        }

        setSubmitting(false)
        setErrorText(error)
      })
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          NKE Platform
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitLogin}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label={t('emailAddress')}
            name='email'
            autoComplete='email'
            autoFocus
            value={inputData.email}
            onChange={handleChange}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={inputData.password}
            onChange={handleChange}
          />
          {!submitting && ErrorText && (
            <Alert severity='error'>{ErrorText}</Alert>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={submitting}
          >
            {t('login')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href='/login/resetpassword' variant='body2'>
                {t('forgotPassword')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default Login
